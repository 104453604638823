import UrlHandlerApiService from "@/core/services/url-handler.service";
import Vue from "vue";

const MFCategoriesApiService = {
  URL: "",
  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
  },

  GET_MF_CATEGORIES_API: "api/main-feed/custom-categories/all",
  MF_ACTIVE_API: (id, publish) =>
    `api/main-feed/custom-categories/${id}/visible?published=${publish}`,
  GET_SINGLE_MF_CATEGORIES_API: id => `api/main-feed/custom-categories/${id}`,
  EDIT_MF_CATEGORY_API: id => `api/main-feed/custom-categories/${id}`,
  CREATE_NEW_MF_CATEGORIES_API: "api/main-feed/custom-categories",
  ITEMS_API: "api/shop/product",
  GET_MF_CATEGORIES_DEEPLINK: id => `api/main-feed/custom-categories/${id}/deeplink`,
  GET_PRODUCTS_IDS: "api/shop/product/by-ids",

  getAllMFCategories() {
    return Vue.axios.get(this.URL + this.GET_MF_CATEGORIES_API).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  updateActiveCategory(id, publish) {
    return Vue.axios.put(this.URL + this.MF_ACTIVE_API(id, publish)).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  getMFCategory(id) {
    return Vue.axios.get(this.URL + this.GET_SINGLE_MF_CATEGORIES_API(id)).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  createMFCategory(body) {
    return Vue.axios.post(this.URL + this.CREATE_NEW_MF_CATEGORIES_API, body).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  editMFCategory(id, body) {
    return Vue.axios.put(this.URL + this.EDIT_MF_CATEGORY_API(id), body).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  getProducts(params) {
    const config = {
      params
    };
    return Vue.axios.get(this.URL + this.ITEMS_API, config).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  deleteCategory(id) {
    return Vue.axios.delete(this.URL + this.GET_SINGLE_MF_CATEGORIES_API(id)).catch(error => {
      // console.log(error);
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  getMFCategoryDeeplink(id) {
    return Vue.axios.post(this.URL + this.GET_MF_CATEGORIES_DEEPLINK(id)).catch(error => {
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  },
  getProductByIds(ids) {
    return Vue.axios.post(this.URL + this.GET_PRODUCTS_IDS, {ids}).catch(error => {
      throw new Error(`[KT] MFCategoriesService ${error}`);
    });
  }
};

export default MFCategoriesApiService;
